<template>
  <div
    class="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-3 py-5"
  >
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>